<template>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Reset Password v1 -->
        <b-card class="mb-0">
  
          <!-- logo -->
          <b-link class="brand-logo">
            <img :src="require('@/assets/vagsis_logo.png')" alt="logo"  class="w-50"/>  
  
           
          </b-link>
  
          <b-card-title class="mb-1">
            Şifre Sıfırlama 🔒
          </b-card-title>
          <b-card-text class="mb-2">
           Lütfen yeni şifrenizi ilgili alanlara giriniz!
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >
  
              <!-- password -->
              <b-form-group
                label="Yeni Şifre"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">{{ 'şifre girişi zorunludur' }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Şifreyi Tekrar Giriniz"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">{{ "şifreniz en az 8 karakter uzunluğunda olmalıdır, 1 büyük harf, 1 küçük harf, 1 rakam ve 1 özel karakter içermelidir!" }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Şifreyi Onayla
              </b-button>
            </b-form>
          </validation-observer>
  
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Giriş sayfasına git
            </b-link>
          </p>
  
        </b-card>
      <!-- /Reset Password v1 -->
      </div>
    </div>
  
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
  } from 'bootstrap-vue'
  import { required } from '@validations'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {Api} from '@/helpers/Api/apiConfig'
import router from '@/router'
  
  export default {
    components: {
      VuexyLogo,
      BCard,
      BButton,
      BCardTitle,
      BCardText,
      BForm,
      BFormGroup,
      BInputGroup,
      BLink,
      BFormInput,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        userEmail: '',
        cPassword: '',
        password: '',
        token:'',
        // validation
        required,
  
        // Toggle Password
        password1FieldType: 'password',
        password2FieldType: 'password',
      }
    },
    computed: {
      password1ToggleIcon() {
        return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      password2ToggleIcon() {
        return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    mounted() {
      this.token = this.$route.query.token
    },
    methods: {
      togglePassword1Visibility() {
        this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
      },
      togglePassword2Visibility() {
        this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
      },
      validationForm() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            Api("POST", "User/ResetPassword" ,{
            token: this.token,
            password: this.password
          
          }).then(response => {
            console.log(response.data)
            if(response.data.isSuccess==true){
                
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'İşlem Başarılı!',
                text: 'Lütfen giriş yapınız!',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            router.push({name:'auth-login'})
            }else{

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'İşlem Bşarısız',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            }

          }).catch(error => {
            console.log(error)
          })



          }
        })
      },
    //   validationForm() {
    //     this.$refs.simpleRules.validate().then(success => {
    //       if (success) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Form Submitted',
    //             icon: 'EditIcon',
    //             variant: 'success',
    //           },
    //         })
    //       }
    //     })
    //   },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  